/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Direction, Directions } from "./../types/direction";
import type wrld from "wrld.js";
import RouteViewPolylineFactory from "./RouteViewPolylineFactory";

export type RouteViewControllerConfig = {
  // Not documenting this since the icon url created has a hard coded icon1_ in the title.
  iconBaseUrl?: string;
  // Not documenting this, reason above.
  directionIconToMarkerIcon?: {
      enter?: string;
      exit?: string;
      stairs_up?: string;
      stairs_down?: string;
      escalator_up?: string;
      escalator_down?: string;
      lift?: string;
  };
  // Not documenting this, reason above.
  markerIcons?: {
      start?: string,
      finish?: string,
      container?: string
  };
  polyline?: {
    weight?: number;
    miterLimit?: number;
    elevation?: number;
    defaultColor?: string; // Would be good to allow users to specify colours for each route.
    displayOption?: wrld.Polyline.Options["displayOption"];
  };
};

export default class RouteViewController {
  private _map: wrld.Map;
  private _config: RouteViewControllerConfig;
  private _polylineFactory: RouteViewPolylineFactory;
  private _directions: Directions;
  private _polylines: wrld.Polyline[];
  private _markers: wrld.Marker[];

  constructor(map: wrld.Map, config: RouteViewControllerConfig) {
    this._map = map;
    this._config = Object.assign(RouteViewController.buildDefaultConfig(), config);
    this._polylineFactory = new RouteViewPolylineFactory(this._config.polyline);
    this._directions = [];
    this._polylines = [];
    this._markers = [];
  }

  static buildDefaultConfig(): RouteViewControllerConfig {
    return {
      iconBaseUrl: "https://cdn-webgl.wrld3d.com/wrld-search/latest/assets/svg",
      directionIconToMarkerIcon: {
        enter: "nav_enter",
        exit: "nav_exit",
        stairs_up: "nav_stairs_up",
        stairs_down: "nav_stairs_down",
        escalator_up: "nav_escalator_up",
        escalator_down: "nav_escalator_down",
        lift: "nav_lift"
      },
      markerIcons: {
        start: "nav_start",
        finish: "nav_finish",
        container: "nav_container"
      },
      polyline: {
      }
    };
  }

  setDirections(directions: Directions): void {
    const newDirections = Array.isArray(directions) ? directions : [];
    this._setDirections(newDirections);
  }

  clearDirections(): void {
    this._setDirections([]);
  }


  _setDirections(directions: Directions): void {
    this._directions = directions.map(direction => ({
      ...direction,
      indoorMapId: direction.indoorMapId || "",
      indoorMapFloorId: direction.indoorMapFloorId || 0
    }));

    this._updatePolylines();
    this._updateMarkers();
  }

  _removePolylines(): void {
    this._polylines.forEach(polyline => {
      this._map.removeLayer(polyline);
      // @ts-ignore legacy code accessing undocumented property
      polyline.visible = false;
    });

    this._polylines = [];
  }


  _updatePolylines(): void {

    this._removePolylines();

    const polylines = this._polylineFactory.createPolylinesForRouteDirections(this._directions);

    polylines.forEach((polyline) => {
      polyline.addTo(this._map);
    });
    this._polylines = polylines;
  }

  _removeMarkers(): void {
    this._markers.forEach(marker => {
      this._map.removeLayer(marker);
      // @ts-ignore legacy code accessing undocumented property
      marker.visible = false;
    });

    this._markers = [];
  }


  _updateMarkers(): void {

    this._removeMarkers();

    const markers = this._createMarkers(this._directions);

    markers.forEach((marker) => {
      marker.addTo(this._map);
    });

    this._markers = markers;
  }

  _createMarkers(directions: Directions): wrld.Marker[] {
    const markers = [];

    if (!directions.length) {
      return markers;
    }

    const firstDirection = directions[0];
    const startMarker = this._createMarker(
      firstDirection.path[0],
      firstDirection,
      this._createMarkerIcon(this._iconUrl(this._config.markerIcons.start))
    );

    markers.push(startMarker);

    directions.forEach((direction, index) => {

      const iconUrl = this._iconUrlForDirection(direction);
      if (iconUrl) {
        const icon = this._createMarkerIcon(iconUrl);
        const directionLocation = direction.path.slice(-1)[0];
        const marker = this._createMarker(directionLocation, direction, icon);

        markers.push(marker);

        if (index !== 0) {
          const nextDirectionIndex = index + 2;
          if (nextDirectionIndex in directions) {
            const nextDirection = directions[nextDirectionIndex];
            const nextDirectionLocation = nextDirection.path[0];
            const nextMarker = this._createMarker(nextDirectionLocation, nextDirection, icon);
            markers.push(nextMarker);
          }
        }
      }
    });

    const lastDirection = directions.slice(-1)[0];
    const finishMarker = this._createMarker(
      lastDirection.path.slice(-1)[0],
      lastDirection,
      this._createMarkerIcon(this._iconUrl(this._config.markerIcons.finish))
    );

    markers.push(finishMarker);

    return markers;
  }

  _createMarker(coord: L.LatLng, direction: Direction, icon: L.DivIcon): wrld.Marker {
    // type hack L.marker refers to leaflets, but we're using
    const Marker = L.marker as typeof wrld.marker;
    const marker = Marker(coord, {
      title: direction.instruction, // Will never see title since 'interactive' is set to false on the marker.
      icon: icon,
      indoorMapId: direction.indoorMapId,
      indoorMapFloorId: direction.indoorMapFloorId,
      interactive: false
    });

    return marker;
  }

  _iconUrl(iconKey: string): string {
    const prefix = this._config.iconBaseUrl + "/icon1_";
    const suffix = ".svg";
    return prefix + iconKey + suffix;
  }

  _iconUrlForDirection(direction: Direction): string {
    const { directionIconToMarkerIcon } = this._config;
    if (direction.icon && direction.icon in directionIconToMarkerIcon) {
      return this._iconUrl(directionIconToMarkerIcon[direction.icon]);
    }
    return null;
  }

  _createMarkerIcon(iconUrl: string): L.DivIcon {
    const containerUrl = this._iconUrl(this._config.markerIcons.container);

    const html = "<div class='wrld-routeview-marker-container' style='background-image: url(" + containerUrl + ")' </div> \
                  <div class='wrld-routeview-marker-icon' style='background-image: url(" + iconUrl + ")' </div>";

    return L.divIcon({
      className: "wrld-routeview-marker",
      iconSize: null,
      html: html
    });
  }
}
