import { Direction, Directions } from "./../types/direction";
import type wrld from "wrld.js";

type LineCreationParams = {
  coordinates: L.LatLng[];
  indoorMapId: wrld.Map.MapId;
  indoorMapFloorId: wrld.Map.MapFloorId;
  hasPerPointElevations?: boolean;
};

type RouteViewPolylineFactoryConfig = {
  weight?: number;
  miterLimit?: number;
  elevation?: number;
  defaultColor?: string; // Would be good to allow users to specify colours for each route.
  displayOption?: wrld.Polyline.Options["displayOption"];
};

export default class RouteViewPolylineFactory {
  private _config: RouteViewPolylineFactoryConfig;

  constructor(config: RouteViewPolylineFactoryConfig) {
    this._config = Object.assign(RouteViewPolylineFactory.buildDefaultConfig(), config);
  }

  static buildDefaultConfig(): RouteViewPolylineFactoryConfig {
    return {
      weight: 17.5,
      miterLimit: 10.0,
      elevation: 0.0,
      defaultColor: "#0096ff",
      displayOption: "currentFloor"
    };
  }

  createPolylinesForRouteDirections(directions: Directions): wrld.Polyline[] {
    const polylines = [];

    const creationParams = this._buildLineCreationParams(directions);
    const ranges = this._buildAmalgamationRanges(creationParams);

    ranges.forEach((range) => {
      let anyPerPointElevations = false;
      let joinedCoords: L.LatLng[] = [];
      for (let i = range[0]; i < range[1]; ++i) {
        const creationParam = creationParams[i];
        anyPerPointElevations = anyPerPointElevations || (creationParam.hasPerPointElevations || false);
        joinedCoords = joinedCoords.concat(creationParam.coordinates);
      }

      if (anyPerPointElevations) {
        joinedCoords = joinedCoords.map((coord) => {
          return coord.alt ? coord : L.latLng(coord.lat, coord.lng, 0.0);
        });
      }

      joinedCoords = this._uniqueAdjacentCoords(joinedCoords);

      if (joinedCoords.length > 1) {
        const commonCreationParam = creationParams[range[0]];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore workaround for old code, fix once we have a patched version of L.Wrld or stop using UMD globals
        const Polyline = L.Wrld.polyline as typeof wrld.polyline;
        const polyline = Polyline(joinedCoords, {
          indoorMapId: commonCreationParam.indoorMapId,
          indoorMapFloorId: commonCreationParam.indoorMapFloorId,
          weight: this._config.weight,
          color: this._config.defaultColor,
          miterLimit: this._config.miterLimit,
          elevation: this._config.elevation,
          displayOption: this._config.displayOption
        });
        polylines.push(polyline);
      }
    });

    return polylines;
  }

  _buildLineCreationParams(directions: Directions): LineCreationParams[] {
    let creationParams = [];

    directions.forEach((direction, index) => {
      const directionBefore = (index - 1 < 0) ? null : directions[index - 1];
      const directionAfter = (index + 1 >= directions.length) ? null : directions[index + 1];
      const creationParamsForDirection = this._buildLineCreationParamsForStep(direction, directionBefore, directionAfter);
      creationParams = creationParams.concat(creationParamsForDirection);
    });

    return creationParams;
  }

  _buildLineCreationParamsForStep(direction: Direction, directionBefore: Direction, directionAfter: Direction): LineCreationParams[] {

    if (direction.path.length < 2) {
      return [];
    }

    if (direction.isMultiFloor) {
      const isValidMultiFloorTransition = directionBefore && directionAfter && direction.indoorMapId;
      if (!isValidMultiFloorTransition) {
        return [];
      }
      return this._createLinesForFloorTransition(
        direction.path,
        direction.indoorMapId,
        directionBefore.indoorMapFloorId,
        directionAfter.indoorMapFloorId
      );
    }

    return this._createLinesForRouteDirection(direction);
  }

  _createLinesForRouteDirection(direction: Direction): LineCreationParams[] {
    return [{
      coordinates: direction.path,
      indoorMapId: direction.indoorMapId,
      indoorMapFloorId: direction.indoorMapFloorId
    }];
  }

  _createLinesForFloorTransition(path: L.LatLng[], indoorMapId: wrld.Map.MapId, beforeIndoorMapFloorId: wrld.Map.MapFloorId, afterIndoorMapFloorId: wrld.Map.MapFloorId): LineCreationParams[] {
    const verticalLineHeight = 5.0;
    const lineHeight = (afterIndoorMapFloorId > beforeIndoorMapFloorId) ? verticalLineHeight : -verticalLineHeight;

    const heightsUp = [0, lineHeight];
    const heightsDown = [-lineHeight, 0];
    const beforeCoords = path.slice(0, 2).map((coord, index) => {
      return L.latLng(coord.lat, coord.lng, heightsUp[index]);
    });
    const afterCoords = path.slice(-2).map((coord, index) => {
      return L.latLng(coord.lat, coord.lng, heightsDown[index]);
    });

    return [
      {
        coordinates: beforeCoords,
        indoorMapId: indoorMapId,
        indoorMapFloorId: beforeIndoorMapFloorId,
        hasPerPointElevations: true
      },
      {
        coordinates: afterCoords,
        indoorMapId: indoorMapId,
        indoorMapFloorId: afterIndoorMapFloorId,
        hasPerPointElevations: true
      }
    ];
  }

  _uniqueAdjacentCoords(coords: L.LatLng[]): L.LatLng[] {
    const maxMargin = 1.0E-9;
    const result = coords.filter((coord, index, array) => {
      const prev = array[index - 1];
      if (!prev) {
        return true;
      }
      let altEqual = true;
      if ((coord.alt !== undefined) && (prev.alt !== undefined)) {
        altEqual = (Math.abs(coord.alt - prev.alt) <= maxMargin);
      }
      return !(altEqual && coord.equals(prev, maxMargin));
    });
    return result;
  }

  _buildAmalgamationRanges(creationParams: LineCreationParams[]): number[][] {
    const ranges = [];
    if (creationParams.length <= 0) {
      return ranges;
    }
    let rangeStart = 0;
    for (let i = 1; i < creationParams.length; ++i) {
      const a = creationParams[i - 1];
      const b = creationParams[i];
      if (!this._canAmalgamateAdjacentLineCreationParams(a, b)) {
        ranges.push([rangeStart, i]);
        rangeStart = i;
      }
    }
    ranges.push([rangeStart, creationParams.length]);
    return ranges;
  }

  _canAmalgamateAdjacentLineCreationParams(a: LineCreationParams, b: LineCreationParams): boolean {
    if (a.indoorMapId !== b.indoorMapId) {
      return false;
    }
    if (a.indoorMapFloorId !== b.indoorMapFloorId) {
      return false;
    }
    return true;
  }
}