import type wrld from "wrld.js";
import RouteViewController, { RouteViewControllerConfig } from "./view/RouteViewController";
import { Directions } from "./types/direction";

class WrldRouteView {
  private _routeViewController: RouteViewController;

  constructor(map: wrld.Map, options: RouteViewControllerConfig) {
    this._routeViewController = new RouteViewController(map, options);
  }

  setDirections(directions: Directions): void {
    this._routeViewController.setDirections(directions);
  }

  clearDirections(): void {
    this._routeViewController.clearDirections();
  }
}

const wrldRouteView = (map: wrld.Map, options: RouteViewControllerConfig): WrldRouteView => {
  return new WrldRouteView(map, options);
};

export {
  WrldRouteView,
  wrldRouteView
};
